// 
// tables.scss
//

th {
    font-weight: $table-head-font-weight;
}

td {
    vertical-align: middle;
}

//Table centered (Custom)
.table-centered {
    td,th {
        vertical-align: middle !important;
    }
}


// Table 

.table-nowrap {
    th,td {
        white-space: nowrap;
    }
}

// Custom table components (Custom)
.table {
    .table-user {
        img {
            height: 30px;
            width: 30px;
        }
    }
}

.action-icon {
    color: $gray-600;
    font-size: 1.2rem;
    display: inline-block;
    padding: 0 3px;

    &:hover {
        color: $gray-700;
    }
}

.dataTables_filter {
    display: none;
}
.dataTable th.sorting {
    cursor: pointer;
}
.table-responsive {
    td {
        a {
            text-decoration: underline;
        }
        p:last-child {
            margin-bottom: 0;
        }
    }
}
.dt-responsive {
    td {
        p:last-child {
            margin-bottom: 0;
        }
    }
}

.one-sheet {
    table {
        background-color: #fff;
        width: calc(100% - 30px);
        margin: 15px 15px;
        td {
            padding: 10px 15px;
        }
    }
}
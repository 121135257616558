// 
// backgrounds.scss
//


@each $color,
$value in $theme-colors {
    .bg-soft-#{$color} {
        background-color: rgba(($value), 0.25) !important;
    }
}

.bg-ghost {
    opacity: 0.4;
}
body {
    background-image: url('/dist/assets/images/site-background.png')
}